<template>
  <div>
    <div class="wrap">
      <div class="top-width">

        <div class="header">
          <div class="text">创新创业管理平台</div>
          <div class="nav">
            <div>
              <router-link :to="{ path: '/'}">首页</router-link>
            </div>
            <div>
              <router-link :to="{ path: '/dac/inenDetails'}">创新创业训练计划</router-link>
            </div>
            <div>
              <router-link :to="{ path: '/dac/matchList'}">创新创业竞赛</router-link>
            </div>
            <div>
              <router-link :to="{ path: '/dac/gcList'}">新闻动态</router-link>
            </div>
          </div>
<!--          <div v-if="isLogin">-->
<!--            <a :href='urlName' style="font-size: 18px">-->
<!--              进入平台-->
<!--            </a>-->
<!--            &lt;!&ndash;            <img @click="handleLogout"  style="cursor: pointer;width: 24px;height: 24px" src="~@/assets/djtu/logout.png" alt="">&ndash;&gt;-->
<!--          </div>-->
<!--          <div v-else>-->
<!--            <a href='/user/login' style="font-size: 18px">-->
<!--              &lt;!&ndash;              <img  style="cursor: pointer;width: 24px;height: 24px" src="~@/assets/djtu/login.png" alt="">&ndash;&gt;-->
<!--              登录-->
<!--            </a>-->
<!--          </div>-->
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "Header",
  components: {
  },
  data() {
    return {
      isMob: false,
      isLogin:false,
      urlName:''
    };
  },
  created() {
    // if (Vue.ls.get(ACCESS_TOKEN)) {
    //   this.isLogin = true
    //   let roleCode = JSON.parse(localStorage.getItem("pro__Login_RoleCode")).value
    //   switch (roleCode) {
    //     case 'competition_expert':
    //       this.urlName = '/review'
    //       break;
    //     case 'inen_expert':
    //       this.urlName = '/reviewInen'
    //       break;
    //     case 'credit_audit':
    //       this.urlName = '/credit/teacherAudit'
    //       break;
    //     default:
    //       this.urlName = '/dashboard/analysis'
    //   }
    // }else {
    //   this.isLogin = false
    // }
  },
  mounted() {
    if (this.isMobile()) {
      this.isMob = true;
    } else {
      this.isMob = false;
    }
  },
  methods: {
    // ...mapActions(["Logout"]),
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    handleLogout() {
      // const that = this
      // this.$confirm({
      //   title: '提示',
      //   content: '真的要注销登录吗 ?',
      //   onOk() {
      //     return that.Logout({}).then(() => {
      //       localStorage.removeItem('selectedItems')
      //       window.location.href="/";
      //     }).catch(err => {
      //       that.$message.error({
      //         title: '错误',
      //         description: err.message
      //       })
      //     })
      //   },
      // });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
  cursor: pointer;
}
.warp{
  margin: 0 auto;
}
.top-width{
  width: 100%;
  margin: 0 auto;
  background: #1F87E8;
}
.header{
  width: 1250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.nav{
  color: #fff;
  font-size: 18px;
  display: flex;
}
.nav > div{
  cursor: pointer;
}
.nav > div > a{
  padding: 10px 25px;
  text-align: center;
}
a {
  display: block;
  box-sizing: border-box;
  color: #fff;
  text-decoration: none;
}
.nav > div .router-link-exact-active {
  background: #076CE0;
}
@font-face{
  font-family: fzsht;
  src: url("~@/assets/dac/fzsht.ttf");
}
.text{
  font-family: 'fzsht';
  font-size: 35px;
  letter-spacing: 5px;
  color: #fff;
  padding: 30px;
}

</style>
