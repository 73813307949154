import Vue from 'vue'
import VueRouter from 'vue-router'
import index from "@/views/index"

Vue.use(VueRouter)

const schoolCode = process.env.VUE_APP_SCHOOL_CODE

let routes = [
  {
    path: '/',
    name: 'index',
    component: index
  }
]

if(schoolCode && schoolCode === 'dac'){
  routes = [
    {
      path: '/',
      name: 'index',
      component: index
    },
    {
      path: '/dac/gcList',
      name: 'dacGcList',
      component: () => import("@/views/dac/newsAll/gcList/Index")
    },
    {
      path: '/dac/gcDetails',
      name: 'dacGcDetails',
      component: () => import("@/views/dac/newsAll/gcList/gcDetails")
    },
    {
      path: '/dac/inenDetails',
      name: 'dacInenDetails',
      component: () => import("@/views/dac/inen/InenDetails")
    },
    {
      path: '/dac/matchList',
      name: 'dacMatchList',
      component: () => import("@/views/dac/match/MatchList")
    },
    {
      path: '/dac/matchDetails',
      name: 'dacMatchDetails',
      component: () => import("@/views/dac/match/MatchDetails")
    }
  ]
}



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
