import axios from "axios"

const apiBaseUrl = process.env.VUE_APP_BASE_URL

console.log("apiBaseUrl", apiBaseUrl)

// 创建 axios 实例
const service = axios.create({
    baseURL: apiBaseUrl, // api base_url
    timeout: 360000 // 请求超时时间
})

// response interceptor
service.interceptors.response.use((response) => {
    return response.data
}, undefined)

export {
    service as axios
}
