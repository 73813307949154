<template>
  <div v-if="show && swiperData.length > 0" class="max-width">
    <div style="display: flex;justify-content: space-between;align-items: center">
      <div>
        <div style="font-size: 22px;color: #1B66B5">创新创业竞赛</div>
        <div style="display: flex;align-items: center">
          <span style="width: 50px;background: #1B66B5;height: 3px"></span>&nbsp;&nbsp;
        </div>
      </div>
      <div style="font-size: 18px;color:#B3B3B3;cursor: pointer" @click="jsList">
        MORE+
      </div>
    </div>
    <br>
    <div style="display: flex;" class="js-box">
      <div class="box" v-for="(item,index) in swiperData" v-if="index < 3">
        <div style="font-size: 16px;color: #000;margin-bottom: 13px;width: 355px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">{{item.name}}</div>
        <div style="height: 178px">
          <img v-if="item.cover_photo" style="width: 100%;height: 100%"  :src="getAvatarView(item.cover_photo)" alt="">
          <img v-else style="width: 100%;height: 100%"  :src="defaultImg" alt="">
        </div>
        <br>
        <div style="display: flex">
          <div style="width: 180px;display: flex;align-items: center;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">
            <a-icon type="appstore" />&nbsp;竞赛级别：{{item.level == 1? '国家级': item.level == 2? '省级': item.level == 3? '市级': item.level == 4? '校级': item.level == 5? '院级':'-'}}
          </div>
          <div style="width: 180px;display: flex;align-items: center;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">
            <a-icon type="history" />&nbsp;报名截止：{{item.endTime.split(' ')[0]}}
          </div>
        </div>
        <br>
        <div style="display: flex">
          <div style="width: 180px;display: flex;align-items: center;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">
            <a-icon type="bank" />&nbsp;<div style="display: inline-block;width: 155px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">主办单位：{{item.hostUnit || "-"}}</div>
          </div>
          <div style="width: 180px;display: flex;align-items: center;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">
            <a-icon type="flag" />&nbsp;<div style="display: inline-block;width: 155px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">承办单位：{{item.hostText || "-"}}</div>
          </div>
        </div>
        <br>
        <div @click="goto_detail(item.id)" style="font-size: 17px;color: #0b5cb2;cursor: pointer">了解更多 ></div>
        <div @click="goto_detail(item.id)" style="position: absolute;right: 0;bottom: 11px;cursor: pointer">
          <img src="~@/assets/dlvtc/icon_01.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import defaultImg from '@/assets/dlvtc/match-bg.png'

export default {
  name: 'Match',
  data() {
    return {
      swiperData: [],
      show: false,
      defaultImg: defaultImg,
      url: {
        competition: '/match/competition/homeListFour',
        imgerver: '',
      }
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    this.show = true
    setTimeout(() => {
      var mySwiper = new Swiper('.swiperB', {
        autoplay: 3000,
        loop: false,
        slidesPerView: 2,
        spaceBetween: 20,
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true//修改swiper的父元素时，自动初始化swiper
      })
    }, 1000)
  },
  methods: {
    getAvatarView: function(avatar) {
      return this.url.imgerver + '/' + avatar
    },
    getList() {
    },

    goto_detail(e) {
      this.$router.push({ path: '/dlvtc/matchDetails', query: { comId: e } })
    },
    //查看所有
    jsList() {
      this.$router.push({ path: '/dlvtc/matchList' })
    },
    filterDictTextByCache(){}
  }

}
</script>

<style scoped>
@import '~@/assets/swiper.min.css';
*{
  margin: 0;
  padding: 0;
}
.max-width {
  width: 1280px;
  margin: 0 auto;
}
.js-box .box:last-child{
  margin-right: 0;
}
.box{
  width: 400px;
  background: #fff;
  padding: 15px 20px;
  position: relative;
  margin-right: 40px;
}
</style>
