<template>
  <div>
    <div class="wrap">
      <div class="new-header">
        <div style="display: flex">
          <img style="margin-right: 10px" src="~@/assets/dlvtc/logo.png" alt="" />
        </div>
        <div >
          <top-login></top-login>
        </div>
      </div>
    </div>
    <div :class="isMob ? 'header-bottom1' : 'header-bottom'">
      <div class="max-width">
        <ul class="nav jt">
          <li>
            <router-link :to="{ path: '/' }">首页</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/dlvtc/matchList' }">创新创业竞赛</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/dlvtc/hubList' }">新闻资讯</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/dlvtc/gcList' }">大赛动态</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/dlvtc/ygList' }">活动预告</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/dlvtc/bulletinList' }">政策法规</router-link>
          </li>

          <li>
            <router-link :to="{ path: '/dlvtc/profileList' }">资源下载</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TopLogin from "@/views/common/TopLogin";
export default {
  name: "Header",
  components: {TopLogin},
  data() {
    return {
      schoolCode: process.env.VUE_APP_SCHOOL_CODE,
      isMob: false,
    }
  },
  mounted() {
    if (this.isMobile()) {
      this.isMob = true
    } else {
      this.isMob = false
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    // login() {
    //   this.$router.push({ path: 'login' })
    // }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  cursor: pointer;
}

.wrap {
  height: 181px;
  margin: 0 auto;
  background: url('~@/assets/dlvtc/header.jpg') center center;
  background-size: 100% 100%;
}

.new-header {
  width: 1250px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 188px;
  color: #fff;
  padding-top: 50px;
}

.new-header /deep/ .header-up {
  position: absolute;
  right: 0;
  bottom: -131px;
  margin-right: 0;
}
.header-bottom {
  margin: 0 auto;
  color: #fff;
  background: #0b5cb2;
  position: relative;
  z-index: 11;
  height: 60px;
}

.header-bottom1 {
  width: 1280px;
  margin: 0 auto;
  color: #fff;
  background: url('~@/assets/top-img.png') center center;
  position: relative;
  z-index: 11;
}

.max-width {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav {
  display: flex;
  position: relative;
}

.nav > li {
  width: 155px;
  text-align: center;
  height: 60px;
  line-height: 60px;
  color: #fff;
}

.nav a {
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  color: #fff;
}
.nav .router-link-active{
  color: #fff;
}
.nav .router-link-exact-active {
  background: #fff;
  color: #0C5CB3;
}

.jt > li {
  width: 183px;
  font-size: 18px;
}
.new-header /deep/ .goToLogin{
  color: #fff;
  margin-right: 0;
}
</style>
