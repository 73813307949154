<template>
  <a-carousel autoplay style="width: 1280px;margin: 0 auto">
    <div class="img-box"><img class="img" src="~@/assets/dlvtc/banner.jpg" alt="bg" /></div>
  </a-carousel>
</template>

<script>
export default {
  name: "Carousel"
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  cursor: pointer;
}

.menu {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 1402px;
  height: 150px;
  border: 1px solid #fff;
}

.menu-left {
  text-align: center;
  width: 4%;
  line-height: 1.3;
  height: 150px;
  font-size: 29px;
  color: #fff;
  background: #5379f5;
}

.menu-right {
  width: 96%;
  padding: 0px 6%;
  height: 150px;
  background: #eee;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-right div {
  text-align: center;
  cursor: pointer;
}

.menu-right img {
  margin: 0 auto;
}

.menu-right p {
  font-size: 25px;
  margin-top: 10px;
  color: #000;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  overflow: hidden;
}

.img-box > img {
  width: 100%;
  height: 310px;
  /*object-fit: contain;*/
  margin: 0 auto;
  margin-top: -1px;
}

.img-box {
  width: 1280px;
  /*height: 790px;*/
  margin: 0 auto;
  /*position: relative;*/
}

.title {
  font-size: 82px;
  color: #fff;
  font-family: 'Microsoft YaHei', 微软雅黑;
  font-weight: bold;
  position: absolute;
  left: 208px;
  top: 400px;
}

.title span:last-child {
  color: #333;
  margin-left: 150px;
}

.bgimage {
  margin-top: -84px;
  position: relative;
  z-index: 1;
}

.ant-carousel >>> .slick-slide {
  text-align: center;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}

.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}

.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}

.img-box-all {
  position: absolute;
  top: 30%;
  left: 35%;
  color: #fff;
}

.img-box-all p:first-child {
  font-size: 38px;
}

.img-box-all p:last-child {
  font-size: 24px;
  margin-top: 10px;
}
</style>
