<template>
  <div v-if="this.porData.length != 0">
    <br>
    <div class="max-width">
      <div style="display: flex;align-items: center">
        <div style="width: 10px;height: 10px;border: 2px solid #ccc;border-radius: 50%"></div>
        <div>
          <div style="font-size: 22px;">&nbsp;创新创业训练计划&nbsp;</div>
        </div>
        <div style="width: 10px;height: 10px;border: 2px solid #ccc;border-radius: 50%"></div>
        <div style="width: 1010px;border: 1px solid #ccc;margin-left: 10px"></div>
        <div style="font-size: 14px;color:#000;cursor: pointer;" @click="goto_project_list">
          &nbsp;+more
        </div>
      </div>
    </div>
    <br>
    <br>
      <div class="max-width">
      <div class="por-list" v-for="(item , index) in porData" :key="index" v-if="index<1">
        <div class="middle-pro">
          <img v-if="item.coverPhoto" :src="getAvatarView(item.coverPhoto)" alt="" style="width: 500px;height: 280px;z-index: 1">
          <img v-else src="~@/assets/dac/inen-bg.png" alt="" style="z-index: 1">
          <div class="middle-pro-box">
            <div class="planName">
              <div style="font-size: 24px;font-weight: bold;text-align: center">
                创新创业训练计划
              </div>
              <div style="width:56px;margin-left: 485px;margin-top: 10px;border-top: 4px solid #1972CC;text-align: center">
              </div>
              <br>
              <h4>{{item.planName}}</h4>
              <br>
              <div>{{item.planIntroduction}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAction } from '@/api/manage'
import defaultImg from '@/assets/dac/bg3.jpg'

export default {
  name: 'Inen',
  data() {
    return {
      porData: [],
      defaultImg: defaultImg,
      url: {
        inen: '/inen/projectPlan/homePlanlist',
        imgerver: process.env.VUE_APP_BASE_URL
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getAvatarView: function(avatar) {
      return this.url.imgerver + '/' + avatar
    },
    getList() {
      let param = {}
      getAction(this.url.inen, param).then(res => {
        let arr = []
        if (res.result.length >= 1) {
          arr[0] = res.result[0]
          this.porData = arr
        } else {
          this.porData = res.result
        }
      })
    },
    //查看所有
    goto_project_list() {
      this.$router.push({ path: '/dac/inenDetails' })
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}


.max-width {
  width: 1280px;
  margin: 0 auto;
}

.por-list {
  position: relative;
  margin-bottom: 60px;
}


.middle-pro {
  height: 280px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.middle-pro img {
  height: 83%;
}

.middle-pro-box {

  width: 100%;
  height: 115%;
  margin-left: -135px;
  background: #FFFFFF;
  box-shadow: 8px 8px 14px 0px rgba(177, 177, 177, 0.5);
  color: #000;
  font-size: 18px;
  text-align: center;
  line-height: 3.5;
}
.planName{
  margin-top: 7%;
  margin-left: 123px;
  line-height: 1;
}
.planName > h4 {
  font-size: 18px;
  color: #000;
  width: 80%;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.planName > div {
  font-size: 16px;
  color: #000;
  width: 80%;
  margin: 0 auto;
  text-align: left;
}
</style>
