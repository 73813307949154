<template>
  <div>
    <div class="max-width">
      <div>
        <div style="display: flex;justify-content: space-between;align-items: center">
          <div>
            <div style="font-size: 22px;color: #1B66B5">新闻资讯</div>
            <div style="display: flex;align-items: center">
              <span style="width: 50px;background: #1B66B5;height: 3px"></span>&nbsp;&nbsp;
            </div>
          </div>
          <div style="font-size: 18px;color:#B3B3B3;cursor: pointer" @click="moreXw">
            MORE+
          </div>
        </div>
        <br>
        <div style="display: flex;justify-content: space-between">
          <div style="width: 420px;height: 280px">
            <img style="width: 100%;height: 100%" src="~@/assets/dlvtc/news_01.jpg" alt="">
          </div>
          <div style="margin-left: 30px;background: #fff;width: 830px;padding: 20px 25px">
            <ul class="text-list">
              <li style="display: flex" v-for="item in xwData">
                <div style="width: 66px;height: 61px;line-height: 30px">
                  <div style="background: #0b5cb2;color: #fff;text-align: center">{{ item.createTime.split('-')[1] +'.'+ item.createTime.split('-')[2] }}</div>
                  <div style="color: #0b5cb2;text-align: center">{{ item.createTime.split('-')[0]}}</div>
                </div>
                <div class="x-line" @click="xwDetails(item.id)">
                  <div style="margin-top: 18px;width: 703px;  overflow: hidden;text-overflow: ellipsis;white-space: nowrap;cursor: pointer">{{item.title}}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div style="max-width: 1280px;height: 95px;margin: 0 auto">
      <img style="width: 100%;height: 100%" src="~@/assets/dlvtc/home_02.jpg" alt="">
    </div>
    <br>
    <div class="max-width">
      <div style="display: flex;justify-content: space-between">
        <div style="width: 49%">
          <div style="display: flex;justify-content: space-between;align-items: center">
            <div>
              <div style="font-size: 22px;color: #1B66B5">大赛动态</div>
              <div style="display: flex;align-items: center">
                <span style="width: 50px;background: #1B66B5;height: 3px"></span>&nbsp;&nbsp;
              </div>
            </div>
            <div style="font-size: 18px;color:#B3B3B3;cursor: pointer" @click="moreNotice">
              MORE+
            </div>
          </div>
          <br>
          <div style="">
            <div style="background: #fff;width: 100%;padding: 20px 25px">
              <ul class="text-list">
                <li style="display: flex" v-for="item in tzData">
                  <div style="width: 66px;height: 61px;line-height: 30px">
                    <div style="background: #0b5cb2;color: #fff;text-align: center">{{ item.createTime.split('-')[1] +'.'+ item.createTime.split('-')[2] }}</div>
                    <div style="color: #0b5cb2;text-align: center">{{ item.createTime.split('-')[0]}}</div>
                  </div>
                  <div class="b-line">
                    <div @click="handleGc(item.id)" style="margin-top: 18px;width: 500px;  overflow: hidden;text-overflow: ellipsis;white-space: nowrap;cursor: pointer">{{item.title}}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style="width: 49%">
          <div style="display: flex;justify-content: space-between;align-items: center">
            <div>
              <div style="font-size: 22px;color: #1B66B5">政策法规</div>
              <div style="display: flex;align-items: center">
                <span style="width: 50px;background: #1B66B5;height: 3px"></span>&nbsp;&nbsp;
              </div>
            </div>
            <div style="font-size: 18px;color:#B3B3B3;cursor: pointer" @click="moreNews">
              MORE+
            </div>
          </div>
          <br>
          <div style="">
            <div style="background: #fff;width: 100%;padding: 20px 25px">
              <ul class="text-list">
                <li style="display: flex" v-for="item in zcData">
                  <div style="width: 66px;height: 61px;line-height: 30px">
                    <div style="background: #0b5cb2;color: #fff;text-align: center">{{ item.createTime.split('-')[1] +'.'+ item.createTime.split('-')[2] }}</div>
                    <div style="color: #0b5cb2;text-align: center">{{ item.createTime.split('-')[0]}}</div>
                  </div>
                  <div class="b-line">
                    <div @click="handleBulletin(item.id)" style="margin-top: 18px;width: 500px;  overflow: hidden;text-overflow: ellipsis;white-space: nowrap;cursor: pointer">{{item.title}}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import news from "@/assets/dlvtc/home_title_new1.png"
import notice from "@/assets/dlvtc/home_title_notice.png"
import defaultImg from '@/assets/dlvtc/bg3.jpg'
import { getAction } from "@/api/manage";

export default {
  name: "News",
  data() {
    return {
      news: news,
      notice: notice,
      defaultImg: defaultImg,
      xwData:[],
      tzData: [],
      zcData: [],
      url: {
        list: '/portalmanagement/cmsCatalog/getPortalList',
      }
    }
  },
  created() {
    this.getNewsData()
    this.getXwData()
    this.getNoticeData()
  },
  methods: {
    getNewsData() {
      let param = {}
      param.catalogId = 'dasaidongtai'
      param.pageSize = 5
      getAction(this.url.list, param).then(res => {
        this.tzData = res.result.records
      })
    },
    getNoticeData() {
      let param = {}
      param.catalogId = 'zhengcefagui'
      param.pageSize = 5
      getAction(this.url.list, param).then(res => {
        this.zcData = res.result.records
      })
    },
    getXwData(){
      let param = {}
      param.catalogId = 'xinwenzixun'
      param.pageSize = 4
      getAction(this.url.list, param).then(res => {
        this.xwData = res.result.records
      })
    },
    moreNews() {
      this.$router.push({ path: '/dlvtc/bulletinList' })
    },
    handleBulletin(e) {
      this.$router.push({ path: '/dlvtc/bulletinDetails', query: { id: e, flag: true } })
    },
    moreNotice() {
      this.$router.push({ path: '/dlvtc/gcList' })
    },
    handleGc(e) {
      this.$router.push({ path: '/dlvtc/gcDetails', query: { id: e, flag: true } })
    },
    moreXw() {
      this.$router.push({ path: '/dlvtc/hubList' })
    },
    xwDetails(e) {
      this.$router.push({ path: '/dlvtc/hubDetails', query: { id: e } })
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.max-width {
  width: 1280px;
  margin: 0 auto;
}
.x-line{
  width: 703px;border-bottom: 1px dashed #ccc;font-size: 17px;margin-left: 18px
}
.b-line{
  width: 500px;border-bottom: 1px dashed #ccc;font-size: 17px;margin-left: 18px
}
.text-list li:last-child .x-line:last-child{
  border-bottom: none;
}
.text-list li:last-child .b-line:last-child{
  border-bottom: none;
}
</style>
