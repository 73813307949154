<template>
  <div style="margin-bottom: 90px" v-if="show">
    <div class="por-img">
      <img src="~@/assets/dac/lt.png" alt="" style="width: 422px;height: 65px;">
    </div>
    <div class="swiper-container swiperC">
      <div class="swiper-wrapper">
        <div class="swiper-slide wrap-box" v-for="(item,index) in swiperData" :key="index" @click="hanleLuntan(item)">
          <div style="position: relative">
            <img src="~@/assets/dac/red-t.png" alt="">
            <span
              style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);color:#fff;font-size: 22px;font-weight: bold ">{{item.name | ellipsis}}</span>
          </div>
          <div>{{item.category | ellipsis}}</div>
        </div>
      </div>
      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script>
import { postAction, getAction } from '@/api/manage'
import Swiper from 'swiper'

export default {
  name: 'Luntan',
  data() {
    return {
      show: false,
      swiperData: [],
      url: {
        list: '/forum/fForum/list'
      }
    }
  },
  created() {
    this.getList()
  },
  filters: {
    // 当标题字数超出时，超出部分显示’...‘。此处限制超出8位即触发隐藏效果
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 6) {
        return value.slice(0, 6) + '...'
      }
      return value
    }
  },
  mounted() {
    this.show = true
    setTimeout(() => {
      var mySwiper = new Swiper('.swiperC', {
        autoplay: 3000,
        loop: true,
        slidesPerView: 3,
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true//修改swiper的父元素时，自动初始化swiper
      })
    }, 1000)
  },
  methods: {
    hanleLuntan(e) {
      let comId = e.id
      this.$router.push({ path: '/dac/talkDetails', query: { comId: comId, year: e.year } })
    },
    getList() {
      let param = {}
      getAction(this.url.list, param).then(res => {
        console.log(res)
        this.swiperData = res.result.records
      })
    }
  }
}
</script>

<style scoped>
@import '../../../assets/swiper.min.css';

.swiper-container {
  width: 1280px;
  height: 280px;
  text-align: center;
  margin-top: 30px;
}

.swiper-button-prev {
  left: -2px;
  right: auto;
}

.swiper-button-next {
  right: -2px;
  left: auto;
}

.por-img {
  /*padding-top: 90px;*/
  text-align: center;
}

.wrap-luntan {
  width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 50px;
}

.wrap-box div {
  font-size: 20px;
  line-height: 3;
  text-align: center;
  width: 380px;
  background: #FFFFFF;
  box-shadow: 8px 8px 14px 0px rgba(177, 177, 177, 0.5);
  border-radius: 10px;
  margin: auto;
}

/*.swiper-slide{*/
/*  width: 380px;*/
/*  width:auto;*/
/*  height: 280px;*/
/*}*/
.wrap-box {
  /*width: 380px;*/
  /*height: 280px;*/
  cursor: pointer;
}
</style>
