<template>
  <div>
  <div class="max-width">
    <div>
      <div style="display: flex;justify-content: space-between">
        <div style="width: 420px;height: 280px">
            <a-carousel
              class="bgimage"
              autoplay
              arrows
              >
              <div
                slot="prevArrow"
                slot-scope="props"
                class="custom-slick-arrow"
                style="left: 20px;zIndex: 1;transform:scale(2);"
              >
                <a-icon type="left-circle" />
              </div>
              <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 20px;zIndex: 1;transform:scale(2);">
                <a-icon type="right-circle" />
              </div>
              <div class="img-box">
                <img style="width: 100%;height: 280px" src="~@/assets/dac/dac_02.jpg" alt="bg">
              </div>
              <div class="img-box">
                <img style="width: 100%;height: 280px" src="~@/assets/dac/dac_02.jpg" alt="bg">
              </div>
            </a-carousel>
        </div>
        <div style="margin-left: 30px;background: #fff;width: 830px;padding: 10px 25px">
          <div style="display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #ccc">
            <div style="position: relative">
              <div style="position: absolute;top: -10px;left: -10px">
                <img src="~@/assets/dac/dac_01.png" alt="">
              </div>
              <div style="font-size: 22px;">新闻动态</div>
            </div>
            <div style="font-size: 14px;cursor: pointer;" @click="moreXw">
              +more
            </div>
          </div>
          <ul class="text-list">
            <li style="display: flex;align-items: center;padding: 5px 0" v-for="item in xwData">
              <div style="width: 66px;">
                <div style="background: #0b5cb2;color: #fff;padding-left: 4px;font-size: 16px;font-weight: bold;text-align: center">{{ item.createTime.slice(0, 4) }}</div>
                <div style="background: #0b5cb2;color: #fff;text-align: center">{{ item.createTime.slice(5, 10)}}</div>
              </div>
              <div class="x-line" @click="xwDetails(item.id)">
                <div style="width: 703px;  overflow: hidden;text-overflow: ellipsis;white-space: nowrap;cursor: pointer">{{item.title}}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
    <br>
    <div style="max-width: 1280px;height: 95px;margin: 0 auto">
      <img style="width: 100%;height: 100%" src="~@/assets/dac/dac_03.jpg" alt="">
    </div>
    <br>
    <br>
  </div>
</template>
<script>
import news from '@/assets/dac/home_title_new1.png'
import notice from '@/assets/dac/home_title_notice.png'
import defaultImg from '@/assets/dac/bg3.jpg'
import { getAction } from '@/api/manage'

export default {
  name: 'News',
  data() {
    return {
      news: news,
      notice: notice,
      xwData:[],
      tzData: [],
      zcData: [],
      defaultImg: defaultImg,
      url: {
        list: '/portalmanagement/cmsCatalog/getPortalList',
      }
    }
  },
  created() {
    this.getNewsData()
    this.getXwData()
    this.getNoticeData()
  },
  methods: {
    //  拉取首页门户数据
    getXwData() {
      let param = {}
      param.catalogId = 'xinwenzixun'
      param.pageSize = 4
      getAction(this.url.list, param).then(res => {
        this.xwData = res.result.records
      })
    },
    getNewsData() {
      let param = {}
      param.catalogId = 'dasaidongtai'
      param.pageSize = 5
      getAction(this.url.list, param).then(res => {
        this.tzData = res.result.records
      })
    },
    getNoticeData() {
      let param = {}
      param.catalogId = 'huodongyugao'
      param.pageSize = 5
      getAction(this.url.list, param).then(res => {
        this.zcData = res.result.records
      })
    },

    moreNews() {
      this.$router.push({ path: '/dac/ygList' })
    },
    handleBulletin(e) {
      this.$router.push({ path: '/dac/ygDetails', query: { id: e, flag: true } })
    },
    moreNotice() {
      this.$router.push({ path: '/dac/gcList' })
    },
    handleGc(e) {
      this.$router.push({ path: '/dac/gcDetails', query: { id: e, flag: true } })
    },
    moreXw() {
      this.$router.push({ path: '/dac/gcList' })
    },
    xwDetails(e) {
      this.$router.push({ path: '/dac/gcDetails', query: { id: e } })
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.max-width {
  width: 1280px;
  margin: 0 auto;
}
.x-line{
  width: 703px;font-size: 17px;margin-left: 18px
}
.b-line{
  width: 500px;font-size: 17px;margin-left: 18px
}
.bgimage{
  position: relative;
  z-index: 1;
}

.ant-carousel >>> .slick-slide {
  text-align: center;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}
.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>
