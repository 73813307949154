<template>
  <div>
    <component v-bind:is="schoolCode"></component>
  </div>
</template>

<script>
import dlvtc from "@/views/dlvtc/index"
import dac from "@/views/dac/index"
export default {
  name: "index",
  components: {
    dlvtc,
    dac
  },
  data() {
    return {
      schoolCode: process.env.VUE_APP_SCHOOL_CODE
    }
  },
}
</script>

<style scoped>

</style>
