<template>
  <div class="new-footer">
    <div class="max-width">
      <div class="footer-right">
        <ul>
          <li class="list" v-for="(item, index) in list1" :key="index">
            <router-link style="color: #fff;" :to="{ path: item.path }">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>
      <div style="margin-top: 30px">
        <img style="margin-right: 10px" src="~@/assets/dlvtc/logo.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  created() {
  },
  data() {
    return {
      list1: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '创新创业竞赛',
          path: '/dlvtc/matchList'
        },
        {
          name: '政策法规',
          path: '/dlvtc/bulletinList'
        },
        {
          name: '大赛动态',
          path: '/dlvtc/gcList'
        },
        {
          name: '资源下载',
          path: '/dlvtc/profileList'
        }
      ]
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.new-footer {
  height: 200px;
  background-image: url("~@/assets/dlvtc/footer_bg.jpg");
  background-size: 100% 100%;
}

.max-width {
  width: 1280px;
  margin: 0 auto;
  padding-top: 20px;
  text-align: center;
}

@media screen and (max-width: 1700px) {
  .max-width {
    width: 1280px;
  }
}

ul {
  width: 700px;
  display: flex;
  justify-content: space-between;
}

ul li {
  padding: 6px 0;
}

.list span {
  color: #fff;
  cursor: pointer;
}


.footer-right {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: 20px;
}

</style>
