<template>
  <div v-if="show && swiperData.length > 0" class="max-width">
    <div style="display: flex;align-items: center">
      <div style="width: 10px;height: 10px;border: 2px solid #ccc;border-radius: 50%"></div>
      <div>
        <div style="font-size: 22px;">&nbsp;创新创业竞赛&nbsp;</div>
      </div>
      <div style="width: 10px;height: 10px;border: 2px solid #ccc;border-radius: 50%"></div>
      <div style="width: 1060px;border: 1px solid #ccc;margin-left: 10px"></div>
      <div style="font-size: 14px;color:#000;cursor: pointer;" @click="jsList">
        &nbsp;+more
      </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div style="display: flex;" class="js-box">
      <div class="box" v-for="(item,index) in swiperData" v-if="index < 3">
        <div style="height: 178px;margin-top: -95px">
          <img v-if="item.cover_photo" style="width: 100%;height: 100%"  :src="getAvatarView(item.cover_photo)" alt="">
          <img v-else style="width: 100%;height: 100%"  :src="defaultImg" alt="">
        </div>
        <br>
        <div style="font-size: 16px;color: #000;margin-bottom: 13px;width: 355px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">{{item.name}}</div>
        <div>
          <div style="width: 180px;display: flex;align-items: center;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">
            <a-icon type="history" />&nbsp;报名时间：
          </div>
          <div>{{item.startTime}} 至 {{item.endTime}}</div>
        </div>
        <br>
        <div @click="goto_detail(item.id)" style="position: absolute;right: 0;bottom: 11px;cursor: pointer">
          <img src="~@/assets/dlvtc/icon_01.png" alt="">
        </div>
<!--        <div @click="goto_detail(item.id)" style="font-size: 16px;color: #fff;cursor: pointer;width: 220px;background: #1D75CD;text-align: center;margin: 0 auto">点击查看详细信息 >></div>-->
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import defaultImg from '@/assets/dac/match-bg.png'
import { getAction } from '@/api/manage'

export default {
  name: 'Match',
  data() {
    return {
      swiperData: [],
      show: false,
      defaultImg: defaultImg,
      url: {
        competition: '/match/cCompetition/getList',
        imgerver: process.env.VUE_APP_BASE_URL
      }
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    this.show = true
    setTimeout(() => {
      var mySwiper = new Swiper('.swiperB', {
        autoplay: 3000,
        loop: false,
        slidesPerView: 2,
        spaceBetween: 20,
        prevButton: '.swiper-button-prev',
        nextButton: '.swiper-button-next',
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true//修改swiper的父元素时，自动初始化swiper
      })
    }, 1000)
  },
  methods: {
    getAvatarView: function(avatar) {
      return this.url.imgerver + '/' + avatar
    },
    getList() {
      let param = {}
      getAction(this.url.competition, param).then(res => {
        this.swiperData = res.result
      })
    },

    goto_detail(e) {
      this.$router.push({ path: '/dac/matchDetails', query: { comId: e } })
    },
    //查看所有
    jsList() {
      this.$router.push({ path: '/dac/matchList' })
    }
  }

}
</script>

<style scoped>
@import '~@/assets/swiper.min.css';
*{
  margin: 0;
  padding: 0;
}
.max-width {
  width: 1280px;
  margin: 0 auto;
}
.js-box .box:last-child{
  margin-right: 0;
}
.box{
  width: 400px;
  background: #fff;
  padding: 15px 20px;
  position: relative;
  margin-right: 40px;
  border: 2px solid #ccc;
}
.js-box .box:hover{
  border: 2px solid #0052A7;
}
</style>
