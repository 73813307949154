<template>
  <div class="header-up">
    <div class="max-width">
      <div class="notice">
      </div>
      <top-user class="header-index-right" />
    </div>
  </div>
</template>

<script>
import TopUser from "@/views/common/TopUser";
export default {
  name: "TopLogin",
  components: {TopUser}
}
</script>

<style scoped>

</style>
