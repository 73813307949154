<template>
  <div :class="isMob?'wrap1':'wrap'">
    <keep-alive>
      <Header/>
    </keep-alive>
    <Carousel/>
    <br>
    <News/>
    <Match/>
    <br>
    <br>
    <br>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/views/dlvtc/modules/Header";
import Carousel from "@/views/dlvtc/modules/Carousel";
import News from "@/views/dlvtc/modules/News";
import Match from "@/views/dlvtc/modules/Match";
import Footer from "@/views/dlvtc/modules/Footer";
export default {
  name: "index",
  components:{
    Carousel,
    News,
    Match,
    Footer,
    Header
  },
  data () {
    return {
      isMob:false,
    }
  },
  mounted() {
    this.isMob = !!this.isMobile();
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  }
}
</script>

<style scoped>
.wrap{
  /*width: 1280px;*/
  margin: 0 auto;
  background: #f5f5f5;
}
.wrap1{
  width: 1280px;
  margin: 0 auto;
  background: #f5f5f5;
}
.new-top{
  margin-top: -200px;
  position: relative;
  z-index: 1;
}
</style>
