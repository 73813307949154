<template>
  <div class="new-footer">
    <div class="max-width">
<!--      <div class="footer-right">-->
<!--        <div style="margin-right: 55px;font-weight: bold;font-size: 16px;letter-spacing: 3px">-->
<!--          <img src="~@/assets/dac/menuIcon.png" alt="">-->
<!--          站内导航-->
<!--        </div>-->
<!--        <ul>-->
<!--          <li class="list" v-for="(item, index) in list1" :key="index">-->
<!--            <router-link style="color: #fff;" :to="{ path: item.path }">{{ item.name }}</router-link>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
      <div style="display: flex;justify-content: space-between;padding-top: 40px">
        <div class="text" >
          创新创业管理平台
        </div>
        <div style="width: 1px;border-left: 1px solid #ccc;" ></div>
        <img src="~@/assets/dac/foot_logo.png" alt="">
        <div style="width: 1px;border-left: 1px solid #ccc;" ></div>
        <div>
<!--         <div style="display: inline-block;float: left;line-height: 30px;font-size: 15px;font-weight: normal;margin-left: 30px;">-->
<!--             <div style="display: block;color: #afc0dc;text-align: left;word-break: break-word;">中央校区 :</div>-->
<!--             <div style="display: block;color: #afc0dc;text-align: left;word-break: break-word;">沈阳经济技术开发区沈辽西路111号</div>-->
<!--             <div style="display: block;color: #afc0dc;text-align: left;word-break: break-word;">邮编 : 110870</div>-->
<!--         </div>-->
<!--          <div style="display: inline-block;float: left;line-height: 30px;font-size: 15px;font-weight: normal;margin-left: 30px;">-->
<!--            <div style="display: block;color: #afc0dc;text-align: left;word-break: break-word;">辽阳分校 :</div>-->
<!--            <div style="display: block;color: #afc0dc;text-align: left;word-break: break-word;">辽宁省辽阳市宏伟区光华街30号</div>-->
<!--            <div style="display: block;color: #afc0dc;text-align: left;word-break: break-word;">邮编 : 111003</div>-->
<!--          </div>-->
<!--          <div style="display: inline-block;float: left;line-height: 30px;font-size: 15px;font-weight: normal;margin-left: 30px;">-->
<!--            <div style="display: block;color: #afc0dc;text-align: left;word-break: break-word;">兴顺校区 :</div>-->
<!--            <div style="display: block;color: #afc0dc;text-align: left;word-break: break-word;">沈阳市铁西区兴顺街南十三路1号</div>-->
<!--            <div style="display: block;color: #afc0dc;text-align: left;word-break: break-word;">邮编 : 110023</div>-->
<!--          </div>-->
          <div style="display: inline-block;float: left;line-height: 30px;font-size: 15px;font-weight: normal;color: #afc0dc;">
            辽宁省大连市金普新区同汇路19号 邮编：116600
            <br>
            辽宁省大连市经济技术开发区东北大街92号(老校区)
            <br>
            联系电话(传真)：0411-39256188
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  created() {
  },
  data() {
    return {
      list1: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '大创计划',
          path: '/dac/inenDetails'
        },
        {
          name: '创新创业竞赛',
          path: '/dac/matchList'
        },
        {
          name: '新闻资讯',
          path: '/dac/hubList'
        },
        {
          name: '大赛动态',
          path: '/dac/gcList'
        },
        {
          name: '活动预告',
          path: '/dac/ygList'
        },
        {
          name: '政策法规',
          path: '/dac/bulletinList'
        },
        {
          name: '大赛动态',
          path: '/dac/gcList'
        },
        {
          name: '资源下载',
          path: '/dac/profileList'
        }
      ]
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.new-footer {
  height: 220px;
  background-image: url("~@/assets/dac/footBg.jpg");
  background-size: 100% 100%;
}

.max-width {
  width: 1280px;
  margin: 0 auto;
  padding-top: 20px;
}

@media screen and (max-width: 1700px) {
  .max-width {
    width: 1280px;
  }
}

ul {
  width: 700px;
  display: flex;
  justify-content: space-between;
}

ul li {
  padding: 6px 0;
}

.list span {
  color: #fff;
  cursor: pointer;
}


.footer-right {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: 20px;
}
@font-face{
  font-family: fzsht;
  src: url("~@/assets/dac/fzsht.ttf");
}
.text{
  //width: 435px;
  font-family: 'fzsht';
  font-size: 35px;
  letter-spacing: 5px;
  color: #fff;
  padding-right: 20px;
  padding-top: 22px;
}
</style>
