<template>
  <div :class="isMob?'wrap1':'wrap'">
    <keep-alive>
      <Header/>
    </keep-alive>
    <Carousel/>
    <br>
    <News/>
    <Inen/>
    <Match/>
    <br>
    <br>
    <br>
    <Footer/>
  </div>
</template>

<script>

  import Header from './modules/Header'
  import Carousel from './modules/Carousel'
  import News from './modules/News'
  import Inen from './modules/Inen'
  import Luntan from './modules/Luntan'
  import Match from './modules/Match'
  import Footer from './modules/Footer'
  export default {
    name: 'syu',
    components: {
      Header,
      Carousel,
      News,
      Inen,
      Luntan,
      Match,
      Footer
    },
    data () {
      return {
        isMob:false,
      }
    },
    mounted() {
      if (this.isMobile()) {
        this.isMob = true
      } else {
        this.isMob = false
      }
    },
    methods: {
      isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      },
    }
  }
</script>

<style scoped>
  .wrap{
    /*width: 1280px;*/
    margin: 0 auto;
    background: #f5f5f5;
  }
  .wrap1{
    width: 1280px;
    margin: 0 auto;
    background: #f5f5f5;
  }
  .new-top{
    margin-top: -200px;
    position: relative;
    z-index: 1;
  }
</style>
